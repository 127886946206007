<template>
  <div class="home">
    <navs></navs>
    <consult></consult>
    <div class="container" v-if="img.length > 0">
      <img @click="tzviewwap" :src="img[0].picture" alt="" />
    </div>
    <div class="titletbr">
      <div class="titlist" @click="gittbr(5)">
        <img src="../assets/item_icon1.png" alt="" />
        <div>最新资讯</div>
      </div>
      <div class="titlist" @click="gittbr(2)">
        <img src="../assets/item_icon2.png" alt="" />
        <div>政策普及</div>
      </div>
      <div class="titlist" @click="gittbr(4)">
        <img src="../assets/item_icon3@2x.png" alt="" />
        <div>政策源文</div>
      </div>
      <div class="dian">
        <div :class="flag == 5 ? 'dian1 c' : 'dian1'"></div>
        <div :class="flag == 2 ? 'dian1 c' : 'dian1'"></div>
        <div :class="flag == 4 ? 'dian1 c' : 'dian1'"></div>
      </div>
    </div>

    <div v-show="flag == 2" style="margin-top: 0.2rem">
      <div class="content1">
        <div class="title">
          <div></div>
          <span>《上海政府补贴培训》政策普及</span>
        </div>
        <div class="br"></div>
        <div class="list">
          <router-link
            to="/policydetails?id=27"
            class="item"
            style="width: 100%"
          >
            <img src="../assets/zhengceicon1x.png" /><span
              >2021年上海市最新职业资格等级鉴定目录</span
            >
          </router-link>
          <router-link to="/policydetails?id=28" class="item">
            <img src="../assets/zhengce_icon_2@2x.png" /><span>补贴对象</span>
          </router-link>
          <router-link to="/policydetails?id=30" class="item">
            <img src="../assets/zhengceicon1x.png" /><span>补贴金额</span>
          </router-link>
          <router-link to="/policydetails?id=31" class="item">
            <img src="../assets/zhengce_icon_7@3x.png" /><span>补贴流程</span>
          </router-link>
          <router-link to="/policydetails?id=35" class="item">
            <img src="../assets/zhengce_icon_7@3x.png" /><span>考试流程</span>
          </router-link>
          <router-link to="/policydetails?id=32" class="item">
            <img src="../assets/zhengce_icon_4@2x.png" /><span>分数查询</span>
          </router-link>
          <router-link to="/policydetails?id=33" class="item">
            <img src="../assets/zhengce_icon_4@2x.png" /><span>证书查询</span>
          </router-link>
          <router-link to="/subsidy" class="item" style="width: 100%">
            <img src="../assets/zhengce_icon_5@2x.png" /><span
              >社会化补贴申请联系地址</span
            >
          </router-link>
        </div>
      </div>
      <div class="content1">
        <div class="title">
          <div></div>
          <span>《上海居住证积分》政策普及 </span>
        </div>
        <div class="br"></div>
        <div class="list">
          <router-link to="/policydetails?id=36" class="item">
            <img src="../assets/zhengce_icon_21@2x.png" /><span>政策原文</span>
          </router-link>
          <router-link to="/policydetails?id=37" class="item">
            <img src="../assets/zhengce_icon_25@2x.png" /><span
              >申请单位须知</span
            >
          </router-link>
          <router-link to="/policydetails?id=38" class="item">
            <img src="../assets/zhengce_icon_28.jpg" /><span>新申办流程</span>
          </router-link>
          <router-link to="/policydetails?id=39" class="item">
            <img src="../assets/zhengce_icon_26@2x.png" /><span
              >申请个人书面材料</span
            >
          </router-link>
          <router-link to="/policydetails?id=40" class="item">
            <img src="../assets/zhengce_icon_27@3x.png" /><span>职称目录</span>
          </router-link>
          <router-link to="/policydetails?id=41" class="item">
            <img src="../assets/zhengce_icon_27@3x.png" /><span
              >紧缺专业目录</span
            >
          </router-link>
          <router-link to="/policydetails?id=42" class="item">
            <img src="../assets/zhengce_icon_27@3x.png" /><span>信息变更</span>
          </router-link>
          <router-link to="/policydetails?id=43" class="item">
            <img src="../assets/zhengce_icon_28.jpg" /><span>积分续办流程</span>
          </router-link>
        </div>
      </div>
      <div class="content1">
        <div class="title">
          <div></div>
          <span>《上海居专户》政策普及 </span>
        </div>
        <div class="br"></div>
        <div class="list">
          <router-link to="/policydetails?id=44" class="item">
            <img src="../assets/zhengce_icon_31@2x.png" /><span>申办条件</span>
          </router-link>
          <router-link to="/policydetails?id=45" class="item">
            <img src="../assets/zhengce_icon_32@2x.png" /><span>实施细则</span>
          </router-link>
          <router-link to="/policydetails?id=46" class="item">
            <img src="../assets/zhengce_icon_34@2x.png" /><span>申办流程</span>
          </router-link>
          <router-link to="/policydetails?id=47" class="item">
            <img src="../assets/zhengce_icon_26@2x.png" /><span>申请材料</span>
          </router-link>
          <router-link
            to="/policydetails?id=48"
            class="item"
            style="width: 100%"
          >
            <img src="../assets/zhengce_icon_23@2x.png" /><span
              >专业技术类职业资格目录</span
            >
          </router-link>
          <router-link
            to="/policydetails?id=49"
            class="item"
            style="width: 100%"
          >
            <img src="../assets/zhengce_icon_23@2x.png" /><span
              >技师、高级技师职业目录</span
            >
          </router-link>
          <router-link
            to="/policydetails?id=50"
            class="item"
            style="width: 100%"
          >
            <img src="../assets/zhengce_icon_37@2x.png" /><span
              >居转户社保对照表</span
            >
          </router-link>
        </div>
      </div>
    </div>

    <div v-show="flag == 4 || flag == 5">
      <div class="content">
        <div class="title" style="margin-bottom: 20rpx">
          <div></div>
          <span>{{
            flag == 5 ? "热点资讯" : flag == 4 ? "政策源文" : ""
          }}</span>
        </div>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="(item, index) in list" :key="index">
            <router-link
              v-if="item.style == 1"
              :to="`/policydetails?id=${item.id}`"
              class="item1"
            >
              <img :src="item.picture_two || src" alt="" />
              <div class="item1_title">
                {{ item.title }}
              </div>
              <div class="footer">
                <div>
                  <img src="../assets/time.png" alt="" />{{ item.time }}
                </div>
                <div class="right">
                  <div>
                    <img src="../assets/yanjing.png" alt="" />{{ item.views }}
                  </div>
                  <div>
                    <img src="../assets/zhuanfa_icon@2x.png" alt="" />{{
                      item.forward
                    }}
                  </div>
                </div>
              </div>
            </router-link>
            <router-link
              v-if="item.style == 2"
              :to="`/policydetails?id=${item.id}`"
              class="item2"
            >
              <img class="img" :src="item.picture_one || src" alt="" />
              <div class="item2_right">
                <div class="title">{{ item.title }}</div>
                <div class="conter">
                  {{ item.profiles }}
                </div>
                <div class="footer">
                  <div>
                    <img src="../assets/time.png" alt="" />{{ item.time }}
                  </div>
                  <div class="right">
                    <div>
                      <img src="../assets/yanjing.png" alt="" />{{ item.views }}
                    </div>
                    <div>
                      <img src="../assets/zhuanfa_icon@2x.png" alt="" />{{
                        item.forward
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
            <router-link
              v-if="item.style == 3"
              :to="`/policydetails?id=${item.id}`"
              class="item3"
            >
              <div class="title">{{ item.title }}</div>
              <div class="conter">
                {{ item.profiles }}
              </div>
            </router-link>
          </div>
        </van-list>
      </div>
    </div>

    <div style="height: 1.44rem"></div>
    <tbrs></tbrs>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      src: require("../assets/mr2.png"),
      flag: 5,
      loading: false,
      finished: false,
      pageSize: 1000,
      page: 1,
      list: [],
      type: 5,
      img: [],
    };
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
      invitationinif: (state) => state.invitationinif,
    }),
  },
  methods: {
    ...mapActions(["getnews", "getbanner"]),
    onLoad() {
      // 加载状态结束

      this.loading = false;
    },
    tzviewwap() {
      let url = this.img[0].url.split(",");
      if (url[1] == 1) {
        this.$router.push({
          path: "/policydetails",
          query: {
            id: url[0],
          },
        });
      }
      if (url[1] == 2) {
        this.$router.push({
          path: "/traindetails",
          query: {
            id: url[0],
          },
        });
      }
      if (url[1] == 3) {
        this.$router.push({
          path: "/traindetails",
          query: {
            id: url[0],
          },
        });
      }
      if (url[1] == 4) {
        this.$router.push({
          path: "/instrumentdetails",
          query: {
            id: url[0],
          },
        });
      }
    },
    gittbr(val) {
      this.type = val;
      this.flag = val;
      if (val != 2) {
        this.getnews({
          page: this.page,
          pageSize: this.pageSize,
          type: this.type,
        }).then((res) => {
          if (res.SuccessCode == 200) {
            res.ResponseBody.data.forEach((e) => {
              e.time = e.time.substring(0, 10);
            });
            this.list = res.ResponseBody.data;
          }
        });
      }
    },
  },
  mounted() {
    if (this.userinfo == "") {
      this.$dialog
        .confirm({
          title: "",
          message: "为了更好的体验请登录",
        })
        .then(() => {
          this.$router.push({
            path: "/user",
          });
        })
        .catch(() => {});
    }
    //组件不生效偷懒做法
    this.getnews({
      page: this.page,
      pageSize: this.pageSize,
      type: this.type,
    }).then((res) => {
      if (res.SuccessCode == 200) {
        res.ResponseBody.data.forEach((e) => {
          e.time = e.time.substring(0, 10);
        });
        this.list = res.ResponseBody.data;
      }
    });
    this.getbanner({}).then((res) => {
      if (res.SuccessCode == 200) {
        this.img = res.ResponseBody;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.container {
  img {
    height: 3.8rem;
    width: 7.5rem;
  }
}

.titletbr {
  position: relative;
  z-index: 100;
  margin-left: 0.23rem;
  margin-top: -1.2rem;
  display: flex;
  justify-content: space-around;
  width: 7.04rem;
  height: 2.8rem;
  background: #ffffff;
  box-shadow: 0px 0.05rem 0.14rem 0.03rem rgba(148, 148, 148, 0.23);
  border-radius: 0.2rem;
  .titlist {
    margin-top: 0.5rem;
    text-align: center;
    font-size: 0.26rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    img {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  .dian {
    position: absolute;
    top: 2.4rem;
    left: 3.01rem;
    display: flex;
    .dian1 {
      width: 0.16rem;
      height: 0.16rem;
      border-radius: 0.08rem;
      margin-right: 0.13rem;
      background: #b0ccff;
      transition: width 0.5s;
    }
    .c {
      width: 0.6rem;
      background-color: #065eff;
    }
  }
}
.content {
  width: 7rem;
  height: 100%;
  margin-top: 0.4rem;
  padding: 0 0.25rem;
  .title {
    margin-bottom: 0.28rem;
    display: flex;
    div {
      transform: translateY(0.08rem);
      width: 0.07rem;
      height: 0.3rem;
      background: #458aec;
    }
    span {
      margin-left: 0.15rem;
      font-size: 0.3rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .item1 {
    display: block;
    margin-bottom: 0.45rem;
    img {
      width: 7rem;
      height: 2.4rem;
    }
    .item1_title {
      width: 7rem;
      margin-top: 0.1rem;
      font-size: 0.28rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .footer {
      margin-top: 0.08rem;
      display: flex;
      justify-content: space-between;
      font-size: 0.24rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #cdcdcd;
      .right {
        display: flex;
        div {
          margin-left: 0.33rem;
        }
      }
      img {
        margin-right: 0.1rem;
        width: 0.22rem;
        height: auto;
      }
    }
  }
  .item2 {
    display: block;
    margin-bottom: 0.45rem;
    display: flex;
    .img {
      width: 2.07rem;
      height: 1.54rem;
      margin-right: 0.2rem;
    }
    .item2_right {
      .title {
        font-size: 0.28rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        margin-bottom: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      .conter {
        font-size: 0.28rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #7c7c7c;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .footer {
        margin-top: 0.08rem;
        display: flex;
        justify-content: space-between;
        font-size: 0.24rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #cdcdcd;
        .right {
          display: flex;
          div {
            margin-left: 0.33rem;
          }
        }
        img {
          margin-right: 0.1rem;
          width: 0.22rem;
          height: auto;
        }
      }
    }
  }
  .item3 {
    display: block;
    margin-bottom: 0.45rem;
    .title {
      font-size: 0.28rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      margin-bottom: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .conter {
      font-size: 0.28rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #7c7c7c;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
}
.content1 {
  margin-top: 0.2rem;
  overflow: hidden;
  width: 7.05rem;
  padding: 0 0.2rem;
  margin: 0 0.2rem;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 0.05rem 0.14rem 0.03rem rgba(148, 148, 148, 0.23);
  border-radius: 0.2rem;
  margin-bottom: 27px;
  .title {
    margin-top: 0.34rem;
    display: flex;
    font-size: 0.32rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    div {
      transform: translateY(0.06rem);
      width: 0.08rem;
      height: 0.34rem;
      margin-right: 0.1rem;
      background: #458aec;
    }
  }
  .br {
    width: 100%;
    height: 0.01rem;
    margin: 0.3rem 0;
    background-color: #d5d5d5;
  }
  .list {
    margin-bottom: 0.3rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 50%;
      margin-bottom: 0.13rem;
      font-size: 0.32rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      img {
        transform: translateY(0.08rem);
        margin-right: 0.1rem;
        width: 0.4rem;
        height: 0.4rem;
      }
    }
  }
}
</style>
